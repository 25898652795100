module.exports = {
  siteTitle: 'Car Collectors Choice', // <title>
  manifestName: 'directive',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/ccc-logo.png',
  heading: 'Car Collectors Choice',
  subHeading: 'We bring the technical knowledge & experience',
  // social
  socialLinks: [
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://www.youtube.com/channel/UC20NgD3MgX7Mm04_VJNkPtg',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/SaltechPack',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/SalTechEasyPackaging/',
    },
    {
      icon: 'fa-pinterest',
      name: 'Email',
      url: 'https://www.pinterest.dk/SalTechEasyPack/',
    },
  ],
};
