import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic1 from '../assets/images/pic01.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>We visited a Vulcanizing Shop in Panay</h2>        
      </header>

      <div className="box container">
        <section>
          <p>            
              <img src={pic1} alt="" width="100%"/>            
          </p>
          <blockquote>
            During our stay in the Philippines, we drove around on the island of Panay. 
            On going out from the city limits, with direction into the mountains, our car 
            needed some simple air in the tires. So what else to do, than to stop, at the 
            local vulcanizing shop. and have then start the only electrical machine they had, 
            a compressor.
          </blockquote>
          <blockquote>
            The sign says all. Not a big deal but they have service hours like nowhere else 24/7. 
            Not many tools, but they can repair most 4 wheels drives, trucks and jeepney´s. I would 
            not recommend to let them touch your Porsche, but they probably never seen one anyway. 
            Most European cars are not part of the street picture of Panay and for sure not the ones 
            we would normally write about here on Car Collector’s Choice.
          </blockquote>
          <blockquote>
            We don’t recommend this tire repair on your 250 Km/hour car, but great for saving money on 
            your bulldozer. By the way we paid 20 peso for the work and service done, something like 40 
            US cents. It costs 30 peso for flag down on a taxi, so this was at great cost compared
          </blockquote>
        </section>
        <section>
          <header>
            <h3>Blog list</h3>
          </header>
          <ul className="default">
            <li>
              <Link to="/we-visited-a-vulcanizing-shop-in-panay">WE VISITED A VULCANIZING SHOP IN PANAY</Link>
            </li>
            <li>
              <Link to="/we-visited-a-car-dealer">WE VISITED A CAR DEALER</Link>
            </li>
            <li>
              <Link to="/we-visited-a-workshop">WE VISITED A WORKSHOP</Link>
            </li>           
          </ul>
          <p><Link to="/">Back to Home Page</Link></p>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Contact Us for more information</h3>
        <p>
          Car collectors choice aim to share the knowledge and experience in an online world. We offer design and build services for you to have an idea.
          Feel free to contact us and we will get back to you as soon as it possible.
        </p>
        <ul className="actions special">
          <li>
            <a href="mailto:support@sal-tech.com?Subject=Carcollectorschoice%20Inquiry" className="button" target="_top">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
