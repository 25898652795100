import React from 'react';
import config from '../../config';
import logoc from '../assets/images/ccc-logo.png';

export default function Header() {
  return (
    <div id="header">    	
      	<span className="icon">
      		<a href="/">
	            <img src={logoc} alt="" />
	        </a>
      	</span>
      	<h1>{config.heading}</h1>
      	<p>{config.subHeading}</p>
    </div>
  );
}
